// /* @tailwind base
//  */
// /* http://meyerweb.com/eric/tools/css/reset/ 
//    v2.0 | 20110126
//    License: none (public domain)
// */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video 
  margin: 0
  padding: 0
  border: 0
  font-size: 100%
  font: inherit
  font-family: Roboto, Arial, Helvetica, sans-serif
  vertical-align: baseline

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section 
  display: block

body 
  line-height: 1

ol,
ul 
  list-style: none

blockquote,
q 
  quotes: none

blockquote:before,
blockquote:after,
q:before,
q:after 
  content: ''
  content: none

table 
  border-collapse: collapse
  border-spacing: 0

@tailwind base
@tailwind utilities

body
  margin: 0
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  
html
  font-size: 16px
  font-family: 'Roboto'
  --base-1: #edeae2
  --base-2: #ffffff
  --base-3: #6a6a63
  --base-4: #040503
  --gray-5: #d1b888
  --gray-6: #bbb5aa
  --gray-7: #b77250
  --gray-8: #f9f9f9
  --gray-9: #748b89

code 
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

::-webkit-scrollbar 
  width: 14px


::-webkit-scrollbar-thumb 
  border: 4px solid rgba(0, 0, 0, 0)
  background-clip: padding-box
  border-radius: 9999px
  background-color: var(--gray-6)

.test-sass
  background-color: red